<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">我要入园</el-breadcrumb-item>
      <el-breadcrumb-item>入园申请</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="header">
      <div class="tab-box">
        <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="一般项目入园申请" name="General"></el-tab-pane>
          <el-tab-pane label="应急项目入园申请" name="Security"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="header-right">
        <!-- <article>事项编码：1224225522522255wsdsfsfsfwef223</article> -->
        <!-- <el-button class="btn" @click="toPage">在线申请</el-button> -->
        <el-button class="" @click="toList">在线申请</el-button>
      </div>
    </div>
    <div class="basic">
      <div class="title">
        <div class="line"></div>
        <div class="text">基础信息</div>
      </div>
      <ul>
        <li>
          <img src="@/assets/img/EnterprisesInpark/rysq_img_jbxx01.png" alt="" />
          <p>45日内办结</p>
        </li>
        <li>
          <img src="@/assets/img/EnterprisesInpark/rysq_img_jbxx02.png" alt="" />
          <p>1次到场</p>
        </li>
        <li>
          <img src="@/assets/img/EnterprisesInpark/rysq_img_jbxx03.png" alt="" />
          <p>不收取费用</p>
        </li>
      </ul>
    </div>
    <div class="check">
      <div class="title">
        <div class="line"></div>
        <div class="text">审批流程</div>
      </div>
      <div class="pic">
        <img src="@/assets/img/EnterprisesInpark/rysq_splc_img1.png" alt="" v-if="activeName == 'General'" />
        <img src="@/assets/img/EnterprisesInpark/rysq_splc_img2.jpg" alt="" v-else />
      </div>
    </div>
    <div class="material">
      <div class="title">
        <div class="line"></div>
        <div class="text">办理材料</div>
      </div>
      <div class="table">
        <el-table :data="table">
          <el-table-column label="材料类型" prop="type" align="center"></el-table-column>
          <el-table-column label="材料名称" prop="name" align="center"></el-table-column>
          <el-table-column label="材料模板" align="center">
            <template slot-scope="scope">
              <el-link :underline="false" class="color2" :href="scope.row.url">下载</el-link>
            </template>
          </el-table-column>
          <el-table-column label="来源渠道" prop="channel" align="center"></el-table-column>
          <el-table-column label="材料必要性" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.need == 1">必要</div>
              <div v-else>非必要</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <ul>
          <li>材料类型</li>
          <li>材料名称</li>
          <li>材料模板</li>
          <li>来源渠道</li>
          <li>材料必要性</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li style="color:blue;cursor: pointer;">下载</li>
          <li>投促局</li>
          <li>必要</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li style="color:blue;cursor: pointer;">下载</li>
          <li>投促局</li>
          <li>必要</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li style="color:blue;cursor: pointer;">下载</li>
          <li>投促局</li>
          <li>必要</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li>无</li>
          <li>投促局</li>
          <li>必要</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li>无</li>
          <li>投促局</li>
          <li>必要</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li>无</li>
          <li>投促局</li>
          <li>必要</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li>无</li>
          <li>投促局</li>
          <li>必要</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li>无</li>
          <li>投促局</li>
          <li>必要</li>
        </ul>
        <ul>
          <li>园区材料</li>
          <li>投资协议模板</li>
          <li>无</li>
          <li>投促局</li>
          <li>必要</li>
        </ul> -->
      </div>
    </div>
    <div class="accept">
      <div class="title">
        <div class="line"></div>
        <div class="text">审批流程</div>
      </div>
      <article>
        <p>（一）符合国家产业结构规划、产业技术标准和行业发展规划；</p>
        <p>（二）厂房和专用仓库的设计、结构、建筑材料、安全距离以及安全设备、设施符合国家有关标准和规范；</p>
        <p>（三）生产设备、工艺技术符合有关安全生产的技术标准和规程；</p>
        <p>（四）主要负责人具有与所生产民用爆炸物品相适应的安全生产知识和管理能力，相关专业的技术人员占职工人数的比例不得低于15%；</p>
        <p>（五）有健全的安全、质量管理制度和岗位安全责任制度；</p>
        <p>（六）法律、行政法规规定的其他条件。</p>
      </article>
    </div>
    <div class="charge">
      <div class="title">
        <div class="line"></div>
        <div class="text">收费标准</div>
      </div>
      <p>免费</p>
    </div>
    <div class="set">
      <div class="title">
        <div class="line"></div>
        <div class="text">设定依据</div>
      </div>
      <p>《瓯江口产业园区入园准则》</p>
      <p>《中华人民共和国宪法》</p>
    </div>
    <div class="problem">
      <div class="title">
        <div class="line"></div>
        <div class="text">常见问题</div>
      </div>
      <p>无</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      activeName: 'General',
      path: 'General',
      //默认
    };
  },
  computed: {
    ...mapState({
      attachmentUrl: (state) => state.attachmentUrl,
    }),
    table() {
      if (this.activeName == 'General') {
        return [
          {
            type: '园区材料',
            name: '2021温州瓯江口产业集聚区申请入园企业情况登记表(一般项目)',
            url: this.attachmentUrl[0].attachmentUrl,
            channel: '投促局',
            need: 1,
          },
        ];
      } else {
        return [
          {
            type: '园区材料',
            name: '2021年安全应急类项目入驻申请资料模板',
            url: this.attachmentUrl[1].attachmentUrl,
            channel: '政务中心',
            need: 1,
          },
        ];
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name === 'General') {
        this.path = 'General';
      } else if (tab.name === 'Security') {
        this.path = 'Security';
      }
    },
    toPage() {
      this.$router.push({ name: this.path });
    },
    toList() {
      this.$router.push({ name: 'ProjectList' });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__header {
  margin: 0 !important;
}

/deep/ .el-tabs__active-bar {
  height: 0 !important;
  background-color: transparent !important;
}

/deep/ .el-tabs__nav-wrap {
  margin: 0;
  &::after {
    height: 0;
  }
}

/deep/ #tab-General {
  width: 266px;
  height: 76px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 76px;
  text-align: center;
  margin-right: 5px;
  color: #666;
  &::after {
    content: ''; /* To generate the box */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #e5e7ec;
    transform: skew(-8deg);
    margin-left: -5px;
  }
}
/deep/ #tab-Security {
  width: 266px;
  height: 76px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 76px;
  text-align: center;
  color: #666;
  margin-right: 8px;
  &::after {
    content: ''; /* To generate the box */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #e5e7ec;
    transform: skew(-8deg);
  }
}
/deep/ .el-tabs__item.is-active {
  color: #ffffff !important;
  font-weight: 600;
  &::after {
    background-image: linear-gradient(270deg, #024a8f 0%, #4393f8 100%) !important;
  }
}

.title {
  display: flex;
  align-items: center;
  padding: 18px 0;
  border-bottom: 1px solid #dddddd;
  margin: 20px 0 19px;
  .line {
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
    margin-right: 10px;
  }
  .text {
    font-size: 26px;
    color: #333333;
    line-height: 36px;
    font-weight: 600;
  }
}
.box {
  .el-breadcrumb {
    margin: 21px 0;
  }
  .header {
    width: 100%;
    height: 76px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    .tab-box {
      display: flex;
      align-items: center;
      .tabs {
        display: flex;
        align-items: center;
      }
    }
    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-right: 30px;
      article {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #666660;
        letter-spacing: 0;
        text-align: right;
        margin-right: 30px;
      }
      .btn {
        background: #024a8f;
        border-radius: 4px;
        border-radius: 4px;
        color: #ffffff;
      }
    }
  }
  .basic {
    width: 100%;
    height: 408px;
    padding: 0 20px;
    background: #ffffff;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: MicrosoftYaHei-Bold;
        font-size: 20px;
        color: #0e459c;
        letter-spacing: 0;
        line-height: 36px;
      }
    }
  }
  .check {
    width: 100%;
    padding: 0 20px 30px;
    margin-top: 10px;
    background: #ffffff;
    .pic {
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  .material {
    width: 100%;
    // height: 649px;
    padding: 0 20px 20px;
    margin-top: 10px;
    background: #ffffff;
  }
  .accept {
    width: 100%;
    height: 365px;
    padding: 0 20px;
    margin-top: 10px;
    background: #ffffff;
    article {
      p {
        height: 22px;
        margin-bottom: 14px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        line-height: 22px;
      }
    }
  }
  .charge {
    width: 100%;
    height: 142px;
    padding: 0 20px;
    margin-top: 10px;
    background: #ffffff;
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  .set {
    width: 100%;
    height: 186px;
    padding: 0 20px;
    margin-top: 10px;
    background: #ffffff;
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      line-height: 22px;
      text-indent: 28px;
      cursor: pointer;
    }
  }
  .problem {
    width: 100%;
    height: 142px;
    padding: 0 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #ffffff;
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}
</style>
